import axios from 'axios';
import { API_URL } from './constants';



//insurence form api

export const Insurence = async (data) => {
    try {

        return await axios.post(`${API_URL}/insform`, data);
    } catch (error) {
        console.log('something went wrong, Make sure you have filled all information ', error)

    }
}
//blood form api carform

export const Blood = async (data) => {

    try {

        let res = await axios.post(`${API_URL}/bloodform`, data);
        return res;
    } catch (error) {
        console.log('something went wrong ', error)
        return error
    }
}
//carform form api jobform

export const CarApi = async (data) => {

    try {

        let res = await axios.post(`${API_URL}/carform`, data);
        return res
    } catch (error) {
        console.log('something went wrong with car Api ', error)
        return error
    }
}

//jobform form api 

export const JobApi = async (data) => {
console.log(data);
    try {

        let res= await axios.post(`${API_URL}/jobform`, data);
        return res;
    } catch (error) {
        console.log('something went wrong ', error)
        return error;
    }
}

// get all data api

export const GetDataApi = async (data) => {

    try {

        let res= await axios.get(`${API_URL}/formdata`, data)
        return res;
    } catch (error) {
        console.log('something went wrong ', error)
        return error;
    }
}

// admin login api

export const Adminlogin = async (data) => {
    console.log(data);


    try {
        return await axios.post(
            `${API_URL}/Adminlogin`,
            data
        );
    } catch (error) {
        console.log('something went wrong ', error)
        return error;
    }
}
export const LoginApi = async (data) => {
    console.log(data, "from login api");

    try {
        return await axios.post(`${API_URL}/loginapi`, data);
    } catch (error) {
        console.log("something went wrong ", error);
         return error;
    }
};

export const AdminSignup = async (data) => {
    try {
        return await axios.post(`${API_URL}/superadmin`, data);
    } catch (error) {
        console.log("something went wrong ", error);
         return error;
    }
};