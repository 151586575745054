import React from 'react'

export function Footer() {
  return (
    <section className="relative overflow-hidden py-10 bg-black text-white w-full">
      <div className="relative z-10 mx-auto max-w-7xl px-4">
        <div className="-m-6 flex flex-wrap">
          <div className="w-full p-6 md:w-1/2 lg:w-5/12">
            <div className="flex h-full flex-col justify-between">

              <div>
                <p className="mb-4  text-base font-medium">Jeevan Aadhar</p>
                <p className="text-sm text-gray-100">
                  &copy; Copyright 2023. All Rights Reserved by Jeevan Aadhar.
                </p>
                <p className="text-sm text-gray-100">
                  Website developed by{' '}
                  <a
                    href="https://www.linkedin.com/in/rohitnandagawali/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary-500 hover:text-primary-400 transition-colors"
                    aria-label="Visit Rohit Nandagawali's LinkedIn profile"
                  >
                    Rohit Nandagawali
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-6 md:w-1/2 lg:w-2/12">
            <div className="h-full">
              <h3 className="tracking-px mb-9  text-xs font-semibold uppercase text-gray-100">
                Important Links
              </h3>
              <ul>
                <li className="mb-4">
                  <a className=" text-base font-medium text-gray-200 hover:text-gray-700" href="#">
                    Home
                  </a>
                </li>
                <li className="mb-4">
                  <a className=" text-base font-medium text-gray-200 hover:text-gray-700" href="#insurance">
                    Insurance
                  </a>
                </li>
                <li className="mb-4">
                  <a className=" text-base font-medium text-gray-200 hover:text-gray-700" href="#car_rentals">
                    Car Rentals
                  </a>
                </li>
                <li className="mb-4">
                  <a className=" text-base font-medium text-gray-200 hover:text-gray-700" href="#jobs">
                    Jobs
                  </a>
                </li>
                <li className="mb-4">
                  <a className=" text-base font-medium text-gray-200 hover:text-gray-700" href="#blood_donation">
                    Blood Donation
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full p-6 md:w-1/2 lg:w-2/12">
            <div className="h-full">
              <h3 className="tracking-px mb-9  text-xs font-semibold uppercase text-gray-100">
                Support
              </h3>
              <ul>
                <li className="mb-4">
                  <a className=" text-base font-medium text-gray-200 hover:text-gray-700" href="#about">
                    About Company
                  </a>
                </li>


              </ul>
            </div>
          </div>
          <div className="w-full p-6 md:w-1/2 lg:w-3/12">
            <div className="h-full">
              <h3 className="tracking-px mb-9  text-gray-200  text-xs font-semibold uppercase ">
                Legals
              </h3>
              <ul>
                <li className="mb-4">
                  <a className=" text-base font-medium text-gray-200 hover:text-gray-700" href="#">
                    Terms &amp; Conditions
                  </a>
                </li>
                <li className="mb-4">
                  <a className=" text-base font-medium text-gray-200 hover:text-gray-700" href="#">
                    Privacy Policy
                  </a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
