import React, { useState } from 'react'
import { Common } from './common'



export const Rentcar = ({ handleonchange }) => {



  return (
    <div className="flex justify-center items-center   h-full  flex-col w-full md:w-[770px] md:p-2">
      <Common handleonchange={handleonchange} />
      <div className="flex justify-center items-start w-full md:w-[800px] h-full p-1  flex-wrap">
        <span className="flex flex-col md:w-[45%] w-full ">
          <h2 className="ml-2">Chose Your Pick-up location</h2>
          <input
            id="text"
            className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none "
            type="text"
            name="pickupLocation"
            placeholder="Chose Your Pick-up location"
            onChange={(e) => {
              handleonchange(e);
            }}
          />
          <input
            id="text"
            className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none "
            type="text"
            name="dropLocationpinocde"
            placeholder="Pickup Location pincode"
            onChange={(e) => {
              handleonchange(e);
            }}
            maxLength={6}
          />
        </span>
        <span className="flex flex-col md:w-[45%] w-full  ">
          <h2 className="ml-2">Pick-up Date & Time</h2>
          <span>
            <input
              id="text"
              className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none "
              type="date"
              name="pickupDate"
              placeholder="Additional Information (Optional)"
              onChange={(e) => {
                handleonchange(e);
              }}
            />
            <input
              id="text"
              className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none "
              type="time"
              name="pickupTime"
              placeholder="Additional Information (Optional)"
              onChange={(e) => {
                handleonchange(e);
              }}
            />
          </span>
        </span>
        <span className="flex flex-col md:w-[45%] w-full   ">
          <h2 className="ml-2">Chose Your Drop-off location</h2>
          <input
            id="text"
            className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none "
            type="text"
            name="dropLocation"
            placeholder="Chose Your Drop-off location"
            onChange={(e) => {
              handleonchange(e);
            }}
          />
          <input
            id="text"
            className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none "
            type="text"
            name="dropLocationpinocde"
            placeholder="Drop-off location pincode"
            onChange={(e) => {
              handleonchange(e);
            }}
            maxLength={6}
          />
        </span>
        <span className="flex flex-wrap md:w-[45%] w-full ">
          <h2 className="ml-2">Drop-off Date & Time</h2>
          <span>
            <input
              id="text"
              className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none "
              type="date"
              name="dropDate"
              placeholder="Additional Information (Optional)"
              onChange={(e) => {
                handleonchange(e);
              }}
            />
            <input
              id="text"
              className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none "
              type="time"
              name="dropTime"
              placeholder="Additional Information (Optional)"
              onChange={(e) => {
                handleonchange(e);
              }}
            />
          </span>
        </span>
      </div>
    </div>
  );
}
