import React from 'react'

export default function FormHeading({ heading }) {
    return (
        <div className="">

            <div className='font-semibold text-xl text-[#222222]'>{heading}</div>
            <div className="h-1 mt-2 w-full bg-gradient-to-r from-[#015FC9] to-[#84D877] rounded-full" />
        </div>

    )
}
