import { useState } from "react";
import { AdminSignup } from "../../services/api";





export const Superadmin=()=>{
const [data,setdata]=useState();
const handleonchange = (e) => {
  setdata({ ...data, [e.target.name]: e.target.value });
};
const submit=async()=>{
const res=await AdminSignup(data);
console.log(res);
}


    return (
      <div className="flex justify-center items-center bg-slate-400 h-screen w-full">
        <div className="flex justify-center items-center flex-col">
          <h1 className="text-lg font-extrabold my-8">Admin Signup</h1>
          <input
            className="py-2 px-4 rounded-md m-2 w-72"
            type="text"
            name="username"
            placeholder="Username"
            onChange={handleonchange}
          />
          <input
            className="py-2 px-4 rounded-md m-2 w-72"
            type="text"
            name="password"
            placeholder="Password"
            onChange={handleonchange}
          />

          <button onClick={submit} className="text-center px-5 py-1 bg-blue-700 text-white rounded-md my-5">
            Create Admin
          </button>
        </div>
      </div>
    );
}