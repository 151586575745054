import React, { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";

export const Blooddoner = ({ handleonchange }) => {
    const validationSchema = Yup.object().shape({
        bloodGroup: Yup.string().required("Blood group is required"),
        dateOfBirth: Yup.date().required("Date of birth is required"),
        gender: Yup.string().required("Gender is required"),
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        phoneNumber: Yup.string().required("Phone number is required"),
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        whatsappNumber: Yup.string(),
        hasDiabetes: Yup.string().required("Please select if you have diabetes"),
        hasAllergies: Yup.string().required("Please select if you have allergies"),
    });


    const bloodGroups = ['A+', 'B+', 'AB+', 'O+', 'A-', 'B-', 'AB-', 'O-'];
    const formik = useFormik({
        initialValues: {
            bloodGroup: "",
            dateOfBirth: "",
            gender: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            whatsappNumber: "",
            hasDiabetes: "",
            hasAllergies: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // Handle form submission here if needed
            console.log("values from formik", values);
        },
    });

    return (
        <div id="driver" className=" flex justify-center items-center  w-full   md:p-2">

            <form className="  rounded-lg w-full flex justify-center items-center flex-col ">

                {/* box for blood group */}
                <div className="flex justify-between items-center w-full flex-wrap" id="upperbox">
                    <div className="flex w-1/3 mx-5 flex-col h-36 flex-wrap ">
                        {bloodGroups.map((group) => (
                            <label key={group} className="flex flex-wrap m-1">
                                <input
                                    type="radio"
                                    name="bloodGroup"
                                    value={group}
                                    onChange={handleonchange}

                                    className="mr-4"
                                />
                                {group}
                            </label>
                        ))}
                    </div>

                    {/* box for gender and date of birth */}

                    <div className="flex flex-col w-1/3 m-5 h-36">
                        {/* Date of Birth */}
                        <div className="mb-4 md:flex  flex-col hidden">
                            <label htmlFor="dateOfBirth" className="block text-gray-600">Date of Birth</label>
                            <input
                                type="date"
                                id="dateOfBirth"
                                name="dateOfBirth"
                                onChange={handleonchange}

                                className="border-[1px] border-[#828589] rounded w-full p-2"
                                required
                            />
                        </div>

                        {/* Gender */}
                        <div className="mb-4 ">
                            <label className="block text-gray-600">Gender</label>
                            <input
                                type="radio"
                                id="male"
                                name="gender"
                                value="Male"
                                onChange={handleonchange}

                                className="mr-2 "
                            />
                            Male
                            <input
                                type="radio"
                                id="female"
                                name="gender"
                                value="Female"
                                onChange={handleonchange}

                                className="mr-2 "
                            />
                            Female

                        </div>
                    </div>
                </div>

                <div className="mb-4 md:hidden flex w-full flex-col px-6">
                    <label htmlFor="dateOfBirth" className="block text-gray-600">Date of Birth</label>
                    <input
                        type="date"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        onChange={handleonchange}

                        className="border-[1px] border-[#828589] rounded w-full p-2"
                        required
                    />
                </div>

                {/* box for name ,address */}
                <div className="flex flex-wrap h-full justify-between items-center ">
                    {/* Name */}
                    <div className=" md:w-[47%] w-full mx-2 flex-col ">
                        <label htmlFor="name" className="block text-gray-600">First Name</label>
                        <input
                            type="text"
                            id="name"
                            name="firstName"
                            placeholder='Enter Fisrt Name'
                            onChange={handleonchange}
                            className="border-[1px] border-[#828589]border-[1px] border-[#828589]  w-full  p-2 py-1 my-2 rounded-lg"
                            required
                        />

                    </div>
                    {/* Last Name */}
                    <div className="md:w-[47%] w-full mx-2 flex-col rounded-lg ">
                        <label htmlFor="lastName" className="block text-gray-600">Last Name</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder='Enter Last Name'
                            onChange={handleonchange}
                            className="border-[1px] border-[#828589]border-[1px] border-[#828589]  w-full  p-2 py-1 my-2 rounded-lg"
                            required
                        />
                    </div>
                    {/*phone */}
                    <div className="md:w-[47%] w-full mx-2 flex-col rounded-lg">
                        <label htmlFor="phone" className="block text-gray-600">Phone Number</label>
                        <input
                            type="text"
                            id="phone"
                            name="phoneNumber"
                            placeholder='Enter Phone Number'
                            onChange={handleonchange}
                            className="border-[1px] border-[#828589]border-[1px] border-[#828589]  w-full  p-2 py-1 my-2 rounded-lg"
                            required
                            maxLength={10}
                        />
                    </div>

                    {/* number */}
                    <div className="md:w-[47%] w-full mx-2 flex-col rounded-lg">
                        <label htmlFor="email" className="block text-gray-600">Email</label>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            onChange={handleonchange}
                            placeholder='Enter Email'
                            className="border-[1px] border-[#828589]border-[1px] border-[#828589]  w-full  p-2 py-1 my-2 rounded-lg"
                            required
                        />
                    </div>
                </div>
                <div className='flex justify-start flex-col w-full p-2'>
                    <label htmlFor="wp" className="block text-gray-600">Whatsapp number</label>
                    <input id='wp' onChange={handleonchange}
                        className="border-[1px] border-[#828589]border-[1px] border-[#828589]  w-full  p-2 py-1 my-2 rounded-lg"
                        type='text' name='whatsappnumber' placeholder='Whatsapp number' />
                </div>

                {/* box for gender diabities and alergy*/}

                <div className="flex flex-wrap h-full justify-between px-6 md:px-2 md:py-5 items-start w-full">
                    {/* Do you have diabetes? */}
                    <div className="mb-4 ">
                        <label className="block text-gray-600">Do you have diabetes?</label>
                        <input
                            type="radio"
                            id="hasDiabetesYes"
                            name="hasDiabetes"
                            value="Yes"
                            onChange={handleonchange}

                            className="mr-2"
                        />
                        Yes
                        <input
                            type="radio"
                            id="hasDiabetesNo"
                            name="hasDiabetes"
                            value="No"
                            onChange={handleonchange}

                            className="mr-2"
                        />
                        No
                    </div>

                    {/* Do you have allergies? */}
                    <div className="mb-4">
                        <label className="block text-gray-600">Do you have allergies?</label>
                        <input
                            type="radio"
                            id="hasAllergiesYes"
                            name="hasAllergies"
                            value="Yes"
                            onChange={handleonchange}

                            className="mr-2"
                        />
                        Yes
                        <input
                            type="radio"
                            id="hasAllergiesNo"
                            name="hasAllergies"
                            value="No"
                            onChange={handleonchange}

                            className="mr-2"
                        />
                        No
                    </div>
                </div>


            </form>
        </div>
    );
};

export default Blooddoner;
