import "../App.css"
import Text from "./text";
import GradientLine from "./utils/GradientLine";

const Home = () => {
  return (
    <div className="   flex-wrap  flex justify-center items-center w-full   md:flex-row flex-col  md:pl-20 mt-10 md:mt-0 overflow-hidden">
      <div id="home-box" className=" flex justify-start  items-start   flex-col  md:w-1/2 w-full  p-8" >

        <GradientLine />
        <div id="head1" className="md:text-5xl text-3xl  flex flex-col  w-full flex-wrap font-extrabold "><span>Elevate Lifestyle </span>
          <span className="md:mt-[0px]  md:text-5xl text-3xl ">  Through Our</span>

          <div className="md:mt-[0px]  md:flex  md:text-5xl text-3xl  ">Services <Text /></div>
        </div>
        <p className=" text-gray-500 font-inter text-base font-normal" id="head2">
          Discover simplicity and convenience in one place. Get insured, find your dream job, donate blood, or rent a car. Trust us for a brighter future.

        </p>
        <a href="#services" className="rounded-full bg-gradient-to-b from-[#328cf3] to-[#015FC9] shadow-blue-100 shadow-lg  w-48 h-8 flex-shrink-0 text-white mt-8 flex justify-center items-center ">Explore More</a>
      </div>
      <div id="home-box" className="  flex justify-center  items-center md:w-1/2 w-full overflow-hidden " >
        <img data-aos="fade-left" src="images/Hero.png" alt="heroImage" className="md:flex hidden" />
        <img data-aos="fade-up" src="images/hero img mobile.png" alt="heroImage" className="md:hidden flex" />
      </div>

    </div>
  )
}

export default Home;