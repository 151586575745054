import React, { useState } from 'react'
import { Common } from './common'
import { Forbloodpage } from '../insfields';


export const Donate = ({ handleonchange }) => {


    const bloodGroups = ['A+', 'B+', 'AB+', 'O+', 'A-', 'B-', 'AB-', 'O-'];

    // api call function 


    return (
        <div className="flex justify-center items-center  h-full  md:w-[770px] w-full p-2 flex-col ">

            <div className="flex justify-center items-center md:w-[800px] w-full h-full flex-wrap">
                <div className="flex justify-around items-center w-full" id="upperbox">
                    <div className="flex md:w-1/3 w-full mx-5 flex-col h-36 flex-wrap ">
                        {bloodGroups.map((group) => (
                            <label key={group} className="flex flex-wrap m-1">
                                <input
                                    type="radio"
                                    name="bloodGroup"
                                    value={group}
                                    onChange={handleonchange}
                                    className="mr-4"
                                />
                                {group}
                            </label>
                        ))}
                    </div>

                    {/* box for gender and date of birth */}

                    <div className="flex flex-col md:w-1/3 w-full m-5 h-36 ">
                        {/* Date of Birth */}
                        <div className="mb-4 w-full md:flex md:flex-col hidden">
                            <label htmlFor="dateOfBirth" className="block text-gray-600">Date of Birth</label>
                            <input
                                type="date"
                                id="dateOfBirth"
                                name="dateOfBirth"
                                onChange={handleonchange}
                                className="border-[1px] border-[#828589] rounded-lg w-full p-2 "
                                required
                            />
                        </div>

                        {/* Gender */}
                        <div className="mb-4  flex-col  ">
                            <label className="block text-gray-600">Gender</label>

                            <div className="flex flex-col md:flex-row">
                                <div className="">
                                    <input
                                        type="radio"
                                        id="male"
                                        name="gender"
                                        value="Male"
                                        onChange={handleonchange}
                                        className="mr-2"
                                    />
                                    <label htmlFor="male" className='mr-2'>Male</label>
                                </div>

                                <div className="mt-2 ml-0 md:ml-2 md:mt-0">
                                    <input
                                        type="radio"
                                        id="female"
                                        name="gender"
                                        value="Female"
                                        onChange={handleonchange}

                                        className="mr-2 "
                                    />
                                    <label htmlFor="female">Female</label>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
                <div className="mb-4 w-full md:hidden flex flex-col p-2">
                    <label htmlFor="dateOfBirth" className="block text-gray-600">Date of Birth</label>
                    <input
                        type="date"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        onChange={handleonchange}
                        className="border rounded w-full p-2"
                        required
                    />
                </div>
            </div>

            <Common handleonchange={handleonchange} />
            <div className='flex justify-start flex-col w-full px-8'>
                <label htmlFor="wp" className="block text-gray-600">Whatsapp number</label>
                <input id='wp' onChange={handleonchange}
                    className="border-[1px] border-[#828589]border-[1px] border-[#828589]  w-full  p-2 py-1 my-2 rounded-lg"
                    type='text' name='whatsappnumber' placeholder='Whatsapp number' maxLength={10} />
            </div>
            <Forbloodpage handleonchange={handleonchange} />
        </div>
    )
}
