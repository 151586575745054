import { CheckCheck } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'



export const Succes = (props) => {
  return (
    <div className="flex justify-center items-center w-full h-screen bg-gray-100 flex-col">
      <div className="w-1/2  overflow-hidden flex justify-center items-center my-10"><div className='bg-green-600 rounded-full w-24 h-24 flex justify-center items-center'><CheckCheck size={58} strokeWidth={3} color='white' /></div></div>
      <h1>Your Information Submitted Succesfully</h1>
      <h1>Return To HomeScreen</h1>
      <Link to="/" >
        <button className="rounded-md bg-gradient-to-b from-[#328cf3] to-[#015FC9] shadow-blue-100 shadow-lg w-24 h-8 flex-shrink-0 text-white mt-8 px-4">Home</button>
      </Link>
    </div>
  )
}
