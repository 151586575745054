import React, { useEffect, useState } from "react";
import { Driver2 } from "../forms/driver2";
import { Rentcar } from "../forms/rentcar";
import { CarApi } from "../../services/api";
import { Succes } from "../succes";
import FormHeading from "../utils/FormHeading";
import Tab from "../utils/Tab";
import { sendEmail } from "../../services/functions";
import { toast, Toaster } from 'react-hot-toast'
import { Loader2 } from "lucide-react";


export const CarPage = (props) => {
  const [succes, setsucces] = useState(false);
  const [selected, setSelected] = useState("Rent a Car");

  const [data, setdata] = useState({ Type: "CarRental", Formtype: selected });
  useEffect(() => { }, [selected, data]);
  const [ErMsg, setermsg] = useState(false);
  const [loader, setloader] = useState(false);
  const handleonchange = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value, Formtype: selected });
  };
  // api call function

  const submit = async () => {
    setloader(true);
    // sending mail
    sendEmail(data.Type, data.firstName + " " + data.lastName);
    // console.log(data.Type, data.firstName + " " + data.lastName);
    const res = await CarApi(data);

    if (res == undefined) {
      setermsg(true);
      setloader(false);
    }
    if (res?.status === 200) {
      setsucces(true);
      setloader(false);
    }
    else if (res?.response?.status === 400) {
      toast.error(res?.response?.data)
      setloader(false)
    }
  };

  return (
    <>
      <Toaster />

      {succes ? (
        <>
          <Succes />
        </>
      ) : (
        <>
          {" "}
          <div className="h-full w-full flex justify-center items-center bg-gray-100 md:p-8 p-4 flex-col">
            <div className="border-4 border-blue-700 rounded-3xl h-full md:w-[800px] w-full md:p-5 flex items-center justify-center flex-col bg-[#FCFCFE]">
              <div className="flex self-center">
                <FormHeading heading={selected} />
              </div>

              <div className="grid grid-cols-2 w-full  px-6 gap-4 md:gap-24 mt-10">
                <Tab
                  setSelected={setSelected}
                  selected={selected}
                  name={"Rent a Car"}
                  subtittle={"Rent Car Now"}
                />
                <Tab
                  setSelected={setSelected}
                  selected={selected}
                  name={"Be a Driver"}
                  subtittle={"Seat on Driver seat"}
                />
              </div>

              {selected === "Rent a Car" ? (
                <>
                  <Rentcar handleonchange={handleonchange} />
                </>
              ) : (
                <>
                  <Driver2 handleonchange={handleonchange} />
                </>
              )}
              <p className="text-xs text-gray-600 my-2 text-center">
                Tip: Make sure you have filled in all necessary information
                before submitting the form.
              </p>
              {ErMsg && (
                <p className="text-red-500 font-semibold">Please fill all mandatory fields</p>
              )}
              {
                loader ? <button

                  className="rounded-lg  bg-blue-700 p-2 px-10 shadow-lg shadow-blue-700/30 my-4 text-white cursor-not-allowed"
                >
                  <div className="animate-spin">

                    <Loader2 />
                  </div>
                </button> : <button
                  onClick={submit}

                  className="rounded-lg  bg-blue-700 p-2 px-10 shadow-lg shadow-blue-700/30 my-4 text-white"
                >
                  Submit
                </button>
              }

            </div>
          </div>
        </>
      )}
    </>
  );
};
