import React, { useState } from 'react'
import { Common } from './common'



export const Job1 = ({ handleonchange }) => {



  return (
    <div className="flex justify-center items-center pd-10  h-full  md:w-[770px] w-full md:p-2 flex-col">
      <Common handleonchange={handleonchange} />
      <div className="flex justify-center items-start md:w-[800px] w-full h-full md:p-5 flex-wrap">
        <span className="flex flex-col md:w-[45%] w-full ">
          <h2 className="ml-2">Select your skill</h2>
          <select
            onChange={(e) => {
              handleonchange(e);
            }}
            className="border-[1px] border-[#828589]  p-2 m-2 rounded-lg"
            name="skill"
          >
            <option value="Labour">Labour</option>
            <option value="Quality Assurance">Quality Assurance</option>
            <option value="Human Resource">Human Resource</option>
            <option value="Supervisor">Supervisor</option>
            <option value="Manager">Manager</option>
          </select>
        </span>
        <span className="flex flex-col md:w-[45%] w-full ">
          <h2 className="ml-2">About You</h2>
          <textarea
            id="text"
            className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none h-[150px]"
            type="text"
            name="about"
            placeholder="Additional Information (Optional)"
            onChange={(e) => {
              handleonchange(e);
            }}
          />
        </span>
      </div>
    </div>
  );
}
