import React, { useState } from 'react'
import { Common } from './common'



export const Job2 = ({ handleonchange }) => {


    return (
      <div className="flex justify-center items-center pd-10  h-full  flex-col  md:w-[770px] w-full md:p-2">
        <Common handleonchange={handleonchange} />
        <div className="flex justify-center items-start md:w-[800px] w-full h-full md:p-5 flex-wrap">
          <div className="md:w-[45%] w-full h-[100px] flex flex-col ">
            <span className="flex flex-col  ">
              <h2 className="ml-2">Desired Job Position</h2>
              <select
                onChange={(e) => {
                  handleonchange(e);
                }}
                className="border-[1px] border-[#828589]  p-2 m-2 rounded-lg"
                name="skill"
                required
              >
                <option value="Labour">Labour</option>
                <option value="Quality Assurance">Quality Assurance</option>
                <option value="Human Resource">Human Resource</option>
                <option value="Supervisor">Supervisor</option>
                <option value="Manager">Manager</option>
              </select>
            </span>
            <span className="flex flex-col  ">
              <h2 className="ml-2">Current Job Position</h2>
              
              <input
                id="text"
                className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none "
                type="text"
                name="currentPosition"
                placeholder="Job Position"
                onChange={(e) => {
                  handleonchange(e);
                }}
                required
              />
              
            </span>
          </div>
          <span className="flex flex-col md:w-[45%] w-full mt-14 md:mt-0 ">
            <h2 className="ml-2">About You</h2>
            <textarea
              id="text"
              className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none h-[120px]"
              type="text"
              name="about"
              placeholder="Additional Information (Optional)"
              onChange={(e) => {
                handleonchange(e);
              }}
            />
          </span>
        </div>
      </div>
    );
}
