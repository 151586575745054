import React from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const ToExcel = ({ data }) => {
  const formatDataForExport = (data) => {
    // Get all unique column names
    const allColumns = Array.from(
      new Set(data.flatMap((row) => Object.keys(row)))
    );

    // Filter out columns with all empty values
    const nonEmptyColumns = allColumns.filter((col) =>
      data.some((row) => row[col] !== undefined && row[col] !== null)
    );

    // Map data to include only non-empty columns
    return data.map((row) => {
      const newRow = {};
      nonEmptyColumns.forEach((col) => {
        newRow[col] = row[col];
      });
      return newRow;
    });
  };

  const handleExportToExcel = () => {
    const dataForExport = formatDataForExport(data);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Table Data");

    // Define the table header based on the keys of the first row in the data
    const headers = Object.keys(dataForExport[0]);
    worksheet.columns = headers.map((header) => ({
      header,
      key: header,
      width: 15, // Adjust the width as needed
    }));

    // Add data to the worksheet
    dataForExport.forEach((row) => {
      worksheet.addRow(row);
    });

    // Generate a Blob containing the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "table_data.xlsx");
    });
  };

  return (
    <button
      className="font-bold py-2 px-4 rounded-full mx-4"
      onClick={handleExportToExcel}
    >
      Download Excel
    </button>
  );
};
