import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';

const MyTable = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const entriesPerPage = 50;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastEntry = (currentPage + 1) * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = data.slice(indexOfFirstEntry, indexOfLastEntry);

  // Get all unique keys (column names) from the data
  const columns = Array.from(new Set(data.flatMap((row) => Object.keys(row))));

  return (
    <div>
      <table className="min-w-[150px] border-collapse border border-gray-300 mx-1 text-sm">
        <thead>
          <tr className="bg-gray-200">
            {columns.map((column) => (
              <th key={column} className="border border-gray-300 px-2 py-2">
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentEntries.map((row, rowIndex) => (
            <tr key={rowIndex} className="bg-white">
              {columns.map((column) => (
                <td key={column} className="border border-gray-300 px-4 py-2">
                  {row[column]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      
    </div>
  );
};

export default MyTable;
