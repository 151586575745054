import "./text.css"

const Text = () => {
  return (
    <div id="text" className="wrapper -z-10">
      <div className="words ml-0 md:ml-[10px] z-10">
        <span className="word md:text-5xl text-3xl ">Insurance</span>
        <span className="word md:text-5xl text-3xl ">Employement</span>
        <span className="word md:text-5xl text-3xl ">Blood Donation</span>
        <span className="word md:text-5xl text-3xl ">Car Rentals</span>
        <span className="word md:text-5xl text-3xl ">Insurance</span>
      </div>
    </div>
  );
}

export default Text;