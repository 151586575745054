import React from 'react'

export default function AdminCard({ setSelected, selected, name, number }) {
    return (
        <div onClick={() => setSelected(name)} className={` h-[100px] w-[250px] border border-purple-700  bg-[#FCFBFB] ${selected === name ? '  bg-gradient-to-b from-purple-700 to-purple-600 shadow-lg shadow-purple-300 text-white' : ''}  p-2 rounded-lg cursor-pointer mr-3 flex flex-col px-4`}>
            <span>{name}</span>
            <span className="text-6xl font-extrabold">{number}</span>
        </div>
    )
}
