import React, { useState } from 'react'
import { Common } from './common'



export const Driver2 = ({ handleonchange }) => {



    return (
        <div className="flex justify-center items-center   h-full  flex-col w-full  md:w-[770px] p-2" >

            <Common handleonchange={handleonchange} />
            <div className="flex justify-center items-start w-full md:w-[800px] h-full md:p-5 flex-wrap">
                <span className="flex flex-col md:w-[45%] w-full ">
                    <h2 className="">Vehicle Name</h2>
                    <select onChange={(e) => { handleonchange(e) }} className="border-[1px] border-[#828589]  p-2 m-2 rounded-lg" name='skill'>
                        <option value="van">van</option>
                        <option value="suv">suv</option>
                        <option value="sedan">sedan</option>
                        <option value="bus">bus</option>
                        <option value="pickup">pickup</option>
                    </select>
                </span>
                <div className="md:w-[45%] w-full   flex flex-col ">

                    <span className="flex flex-col ">
                        <h2 className="ml-2">Driving License Number</h2>
                        <input id='text' className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none " type='text' name='DrivingLicence'
                            placeholder='Enter Your Driving License Number' onChange={(e) => { handleonchange(e) }} />
                    </span>
                    <span className="flex flex-col  ">
                        <h2 className="ml-2">When can you start?</h2>
                        <input id='text' className="border-[1px] border-[#828589] p-2 m-2 rounded-lg resize-none " type='date' name='startDate'
                            placeholder='Additional Information (Optional)' onChange={(e) => { handleonchange(e) }} />
                    </span>
                </div>

            </div>

        </div>
    )
}
