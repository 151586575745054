
import React, { useState } from 'react'





export const Common = ({ handleonchange }) => {
    return (
      <div className=" flex justify-center items-center md:w-[800px] w-[300px] md:h-full  p-1 flex-wrap  ">
        <span className="flex flex-col md:w-[45%] w-full mt-3">
          <h2 className="ml-2 font-[#222222]">First Name</h2>
          <input
            className=" border-[1px] border-[#828589] p-2 py-1 my-2 md:m-2 rounded-lg"
            type="text"
            name="firstName"
            placeholder="Enter Your Name"
            onChange={(e) => {
              handleonchange(e);
            }}
            required
          />
        </span>
        <span className="flex flex-col md:w-[45%] w-full mt-3">
          <h2 className="ml-2 font-[#222222]">Last Name</h2>
          <input
            className="border-[1px] border-[#828589] p-2 py-1 my-2 md:m-2 rounded-lg"
            type="text"
            name="lastName"
            placeholder="Enter Your last name"
            onChange={(e) => {
              handleonchange(e);
            }}
            required
          />
        </span>
        <span className="flex flex-col md:w-[45%] w-full mt-3">
          <h2 className="ml-2 font-[#222222]">Phone number</h2>
          <input
            className="border-[1px] border-[#828589]border-[1px] border-[#828589]  p-2 py-1 my-2 md:m-2 rounded-lg"
            type="text"
            name="phoneNumber"
            placeholder="Enter Your Number"
            onChange={(e) => {
              handleonchange(e);
            }}
            required
            maxLength={10}
            minLength={10}
          />
        </span>
        <span className="flex flex-col md:w-[45%] w-full mt-3">
          <h2 className="ml-2 font-[#222222]">Email</h2>
          <input
            className="border-[1px] border-[#828589] p-2 py-1 my-2 md:m-2 rounded-lg"
            type="email"
            name="email"
            placeholder="Enter Your Email"
            onChange={(e) => {
              handleonchange(e);
            }}
            required
          />
        </span>
        <span className="flex flex-col md:w-[90%]  w-full flex-wrap mt-3">
          <h2 className="ml-2 font-[#222222]">Address</h2>
          <div className="w-full flex justify-center items-center flex-wrap">
            {" "}
            <input
              className="border-[1px] border-[#828589] p-2 py-1 my-2 rounded-lg md:w-[70%] w-[100%]"
              type="text"
              name="address"
              placeholder="Enter Your Address"
              onChange={(e) => {
                handleonchange(e);
              }}
              required
            />
            <input
              className="border-[1px] border-[#828589] p-2 py-1 my-2 md:m-2 rounded-lg  md:w-[25%] w-[100%]"
              type="text"
              name="pincode"
              placeholder="Enter  Pincode"
              onChange={(e) => {
                handleonchange(e);
              }}
              required
              maxLength={6}
              minLength={6}
            />
          </div>
          <input
            id="text"
            className="border-[1px] border-[#828589] p-2 py-1 my-2 md:m-2 rounded-lg"
            type="text"
            name="address2"
            placeholder="second address (optional)"
            onChange={(e) => {
              handleonchange(e);
            }}
          
          />
        </span>
        <span className="flex flex-col md:w-[45%] w-full mt-3 ">
          <h2 className="ml-2 font-[#222222]">City</h2>
          <input
            id="text"
            className="border-[1px] border-[#828589] p-2 py-1 my-2 md:m-2 rounded-lg"
            type="text"
            name="city"
            placeholder="Enter Your City"
            onChange={(e) => {
              handleonchange(e);
            }}
            required
          />
        </span>
        <span className="flex flex-col md:w-[45%] w-full mt-3">
          <h2 className="ml-2 font-[#222222]">State</h2>
          <input
            id="text"
            className="border-[1px] border-[#828589] p-2 py-1 my-2 md:m-2 rounded-lg"
            type="text"
            name="state"
            placeholder="state"
            onChange={(e) => {
              handleonchange(e);
            }}
            required
          />
        </span>
      </div>
    );
}

export const Blood2 = (props) => {
    const [data, setdata] = useState({})

    const handleonchange = (e) => {
        setdata({ ...data, [e.target.name]: e.target.value })
    }
    // api call function 

    const submit = async () => {
        console.log(data);
    }
    return (
      <div className="flex justify-center items-center pd-10  h-full  flex-col shadow-sm bg-gray-100 w-[770px] p-2">
        <h1>Driver1 </h1>
        <Common handleonchange={handleonchange} />
        <div className="flex justify-center items-center w-[800px] h-full p-5 flex-wrap">
          <span className="flex flex-col w-[45%]">
            <h2 className="ml-2">Type of vehicle</h2>
            <input
              id="text"
              className=" p-2 m-2 rounded-lg"
              type="text"
              name="city"
              placeholder="city"
              onChange={(e) => {
                handleonchange(e);
              }}
              required
            />
          </span>
          <span className="flex flex-col w-[45%]">
            <h2 className="ml-2">Driving License Number</h2>
            <input
              id="text"
              className=" p-2 m-2 rounded-lg"
              type="text"
              name="state"
              placeholder="Enter Your Driving License Number"
              onChange={(e) => {
                handleonchange(e);
              }}
              required
            />
          </span>
          <span className="flex flex-col w-[45%]">
            <h2 className="ml-2">Work History</h2>
            <input
              id="text"
              className=" p-2 m-2 rounded-lg"
              type="text"
              name="city"
              placeholder="city"
              onChange={(e) => {
                handleonchange(e);
              }}
              required
            />
          </span>
          <span className="flex flex-col w-[45%]">
            <h2 className="ml-2">When can you start?</h2>
            <input
              id="text"
              className=" p-2 m-2 rounded-lg"
              type="text"
              name="state"
              placeholder="state"
              onChange={(e) => {
                handleonchange(e);
              }}
              required
            />
          </span>
        </div>
        <div className="flex justify-start items-center  w-[740px] ml-[80px] text-xs">
          <span className="w-[70%]">
            By signing this application form, I accept the following
            requirements of the agreement
            <div> 1. The information about driver's license is valid.</div>
            <div>2. My vehicle is fully insured.</div>
            <div>
              {" "}
              3. I accept all the responsibilities for injury, damage and
              traffic violations.
            </div>
            <div>4. I acknowledge that I do not smoke in the vehicle.</div>
          </span>
        </div>
        <button
          onClick={submit}
          className="rounded-md w-[100px] bg-blue-700 p-2 px-6 mt-4"
        >
          Submit
        </button>
      </div>
    );
}
