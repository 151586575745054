import { Link } from "react-router-dom";
import { services_card_data } from "../const";


const ServicesCard = () => {
  return (
    <div id="services" className="flex justify-center items-center md:flex-row flex-col p-10 px-14 ">{
      services_card_data.map(data => {
        return (
          <div
            key={data.id} data-aos="fade-up"
            className=" flex justify-center flex-col  border-2 w-[300px] h-[290px]  m-7 rounded-lg p-8 shadow-xl shadow-gray-200 "
          >
            <img
              src={data.img}
              alt={data.head}
              className={`w-1/3 ${data.id === 0
                ? "drop-shadow-[0_12px_14px_rgba(211,12,12,0.3)] -z-10"
                : ""
                } ${data.id === 1
                  ? "drop-shadow-[0_12px_14px_rgba(255,190,53,0.4)] -z-10"
                  : ""
                } ${data.id === 2
                  ? "drop-shadow-[0_12px_14px_rgba(200,109,53,0.4)] -z-10"
                  : ""
                } ${data.id === 3
                  ? "drop-shadow-[0_12px_14px_rgba(253,79,134,0.4)] -z-10"
                  : ""
                } `}
            />
            <div className="flex flex-col justify-items-start items-start mt-4 h-1/3 ">
              <span className="text-gray-900 font-inter text-2xl font-bold leading-6">
                {data.head}
              </span>
              <span className="text-gray-900 mt-2 font-inter text-sm font-normal">
                {data.dis}
              </span>
            </div>
            <div className=" flex flex-row w-full mt-2">
              <Link
                to={data.formaddress}
                className="w-full"

              >
                <button className="rounded-md bg-gradient-to-b from-[#328cf3] to-[#015FC9] shadow-blue-100 shadow-lg w-full h-8 flex-shrink-0 text-white mt-8 px-4">
                  {data.btntext}
                </button>
              </Link>
            </div>
          </div>
        );
      })
    }</div>

  )
}

export default ServicesCard;