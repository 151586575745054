import { Check } from 'lucide-react'
import React from 'react'
import GradientLine from './utils/GradientLine'
import about_company from '../images/about_company.svg'


export const About = (props) => {

  const bullets_data = [
    {
      id: 1,
      data: 'Discover Health, Vehicle, and General Insurance'
    },
    {
      id: 2,
      data: 'Find your dream job with featured listings & Company'
    },
    {
      id: 3,
      data: 'Be a hero by donating blood, Stories of lives saved'
    },
    {
      id: 4,
      data: 'Explore the world with cars and drivers for hire'
    },
  ]

  return (
    <div id='about' className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 md:px-24 py-16 md:py-0 px-8 overflow-hidden justify-around items-center">
      <div className="w-full flex flex-col  ">
        <GradientLine />
        <span className="text-black  my-2 font-inter text-lg font-normal" >About Company</span>
        <span className="text-black  my-2 font-inter text-2xl font-semibold">We provide essential services under one roof</span>
        <span className="text-blue-600  my-2 text-justify font-inter text-base font-normal">We believe in empowering individuals within our community. Join us on this transformative journey where everything you need is just a click away.</span>
        <div className="box">
          {
            bullets_data.map((bullet) => (
              <span key={bullet.id} className="flex my-3">
                <div className="h-[20px] md:w-[20px] w-[25px] rounded-full bg-[#47B335] flex justify-center items-center ml-2 mr-4">
                  <Check size={15} strokeWidth={2.75} color="white" />
                </div>
                {bullet.data}
              </span>
            )
            )
          }


        </div>
        <div className="box mt-8 flex justify-between md:w-[60%] w-full">

          <span className="flex flex-wrap w-[200px] justify-start items-start h-[40px]  ml-4  text-gray-700">
            <span className="text-xs text-gray-500">Have any question? Give us Call </span>
            <a href='tel:8421971727' className="text-xs text-gray-500">+91 84219 71727</a>

          </span>
        </div>
      </div>
      <img src={about_company} alt="Jeevan Aadhar" className='w-full  flex self-center' />

    </div>
  )
}
