import { section_data, insurance_data } from "../const";
import { Link } from "react-router-dom";
import { CarTaxiFront, LifeBuoy } from 'lucide-react';
import GradientLine from "./utils/GradientLine";
const ServicesSection = () => {


    return (
        <div className="flex justify-center md:p-0 items-center flex-col w-full  ">
            {
                section_data.map(data => {

                    return (

                        <div key={data.id} id={data.id} className={data.class}>
                            <div className="flex flex-col md:w-1/2 w-full md:py-12 md:px-12 px-8 py-16">
                                <span className="text-gray-800 font-inter font-semibold text-3xl">{data.head1}</span>
                                <GradientLine />
                                {
                                    data.id == "car_rentals" ?
                                        <div className="flex h-full w-full md:m-3 md:flex-row flex-col">
                                            <div data-aos="fade-up" className="flex justify-between items-start flex-col w-full h-[230px] mt-3 ">
                                                <div className="flex justify-start items-start flex-col w-full  ">
                                                    <div className="flex justify-center items-center bg-[#FFCACA99] rounded-2xl h-[80px] w-[80px] p-2">
                                                        <CarTaxiFront color="#AB2929" size={56} strokeWidth={2} />
                                                    </div>

                                                </div>
                                                <span className="text-gray-500 font-inter text-sm font-normal mt-4">
                                                    Explore memorable journeys with our diverse vehicle fleet. Road trips or special occasions
                                                </span>
                                                {/* <div className="flex justify-between items-start w-full my-3">
                                                    <span className="rounded-full text-xs p-1 bg-[#015FC924] flex justify-center items-center w-[48%]">Flexible Rental</span>
                                                    <span className="rounded-full p-1 bg-[#015FC924] flex text-xs justify-center items-center w-[48%]">No Hidden Charge</span>
                                                </div> */}
                                                <Link to={data.formaddress}>
                                                    <button className="rounded-md bg-gradient-to-b from-[#328cf3] to-[#015FC9] shadow-blue-100 shadow-lg  w-48 h-8 flex-shrink-0 text-white mt-4"> Rent Car Now</button>
                                                </Link>
                                            </div>

                                            <div data-aos="fade-up" className="ml-0 md:ml-3 flex justify-between items-start flex-col w-full h-[230px] md:mt-3 mt-16 ">
                                                <div className="flex justify-start items-start flex-col w-full">
                                                    <div className="flex justify-center items-center bg-[#D7E3FBD6] rounded-2xl h-[80px] w-[80px] p-2 ">
                                                        <LifeBuoy color="#2563EB" size={48} strokeWidth={2} /></div>
                                                </div>

                                                <span className="text-gray-500 font-inter text-sm font-normal mt-4">
                                                    Turn your love for the road into a rewarding career, Join our professional driver team
                                                </span>
                                                {/* <div className="flex justify-between items-start w-full text-xs my-3  mt-4">
                                                    <span className="rounded-full p-1 text-xs bg-[#015FC924] flex justify-center items-center w-[48%]">Flexible Rental</span>
                                                    <span className="rounded-full p-1 text-xs bg-[#015FC924] flex justify-center items-center w-[48%]">No Hidden Charge</span>
                                                </div> */}
                                                <Link to={data.formaddress}>
                                                    <button className="rounded-md bg-gradient-to-b from-[#328cf3] to-[#015FC9] shadow-blue-100 shadow-lg  w-48 h-8 flex-shrink-0 text-white mt-4">Apply Now</button>
                                                </Link>
                                            </div>

                                        </div>
                                        :
                                        <>
                                            <span className="text-blue-600 font-inter font-semibold text-4xl mt-4">{data.head2}</span>
                                            <div id="right" className={data.id == "insurance" ? "w-full hidden " : "w-full md:hidden visible "}>
                                                <img src={data.image2} alt="insurance" />
                                            </div>
                                            <span className="text-gray-500 font-inter text-base font-normal mt-4">{data.head3}</span>
                                        </>
                                }

                                {
                                    data.id == "car_rentals" || <Link to={data.formaddress}>
                                        <button className="rounded-md bg-gradient-to-b from-[#328cf3] to-[#015FC9] shadow-blue-100 shadow-lg  w-48 h-8 flex-shrink-0 text-white mt-8">{data.btntext}</button>
                                    </Link>
                                }
                                {
                                    data.id == "car_rentals" && <div id="right" className={"w-full md:hidden "}>
                                        <img src={data.image2} alt="car rentals" />
                                    </div>
                                }

                            </div>
                            <div id="right" className="w-1/2  md:flex hidden ">
                                <img src={data.image} alt="car rentals" className='w-full' />
                            </div>
                            {data.id == "insurance" &&
                                <div className=" w-full  flex justify-center items-center md:flex-row flex-col ml-0 md:-ml-10 p-5 md:mb-10 md:-mt-15">
                                    {insurance_data.map(data3 => {
                                        return (

                                            <div key={data3.id} data-aos="fade-up" id="card3" className="flex md:w-[30%]  md:h-[110px] md:mb-10 mb-12 md:flex-row flex-col justify-center items-center">
                                                <div className=" w-[75px] h-[75px] rounded-full md:mr-5 m-5 flex  justify-center items-center ">
                                                    <div key={data3.id} className={`${data3.id === 0 ? 'bg-[#BFF9B5]' : ''} ${data3.id === 1 ? 'bg-[#FEE2E2]' : ''} ${data3.id === 2 ? 'bg-[#DBEAFE]' : ''} ${data3.id === 3 ? 'bg-[#FFEDD5]' : ''}  h-full w-full rounded-full flex justify-center items-center`}>{data3.icon} </div>


                                                </div>
                                                <div className="w-1/2 flex flex-col md:justify-around justify-center items-center md:items-start">
                                                    <span className="text-gray-800 font-inter text-base font-semibold " >{data3.title1}</span>
                                                    <span className="text-[rgba(34,34,34,0.60)] mt-1 font-inter text-xs font-normal md:text-start text-center">{data3.title2}</span>
                                                </div>

                                            </div>

                                        )
                                    })}
                                </div>
                            }

                        </div>
                    )
                })
            }</div>

    )
}

export default ServicesSection;