import axios from 'axios';
export const sendEmail = async (type, name) => {
    if (type === "BloodDonation") {
        type = "Blood Donation"
    }
    if (type === "CarRental") {
        type = "Car Rentals"
    }

    try {
        // // use this for testing
        // const response = await axios.post('https://formspree.io/f/xdojoool', {
        //     type,
        //     name,
        // });

        // // use following while deploying
        const response = await axios.post('https://formspree.io/f/xleykwol', {
            type,
            name,
        });

        if (response.status === 200) {
            console.log('Form submitted successfully');
        } else {
            console.error('Form submission failed');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
    }
}