import React, { useState } from 'react';
import { Adminlogin, LoginApi } from '../services/api';
import { Loader2 } from 'lucide-react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';


function AdminLoginPage({ setauth }) {

  const [data, setdata] = useState({})
  const [msg, setmsg] = useState("")
  const [loading, setLoading] = useState(false)

  const handleonchange = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value })
  }
  const Login = async () => {


    if (!data?.password || !data?.username) {
      toast.error("Please enter all fields")
    }
    else {
      setLoading(true)
      const res = await LoginApi(data);

      if (res.data) {
        sessionStorage.setItem("user", res.data.refreshToken);
        window.location.reload(true);
        setLoading(false)
      } else {
        setmsg("Wrong Credentials");
        setLoading(false)
      }
    }



  }

  return (
    <div className="flex h-screen justify-center items-center">
      <Toaster />

      <div className="bg-white  border-2 shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl mb-4">Login</h2>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
            Username
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Username"
            name="username"
            onChange={(e) => handleonchange(e)}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="Password"
            name="password"

            onChange={(e) => handleonchange(e)}
          />
          <h1 className='text-sm text-red-500'>{msg}</h1>
        </div>
        {
          loading ? <button

            className="rounded-lg  bg-blue-700 p-2 px-10 shadow-lg shadow-blue-700/30 my-4 text-white cursor-not-allowed"
          >
            <div className="animate-spin">

              <Loader2 />
            </div>
          </button> : <button
            onClick={Login}

            className="rounded-lg  bg-blue-700 py-2 px-10 shadow-lg shadow-blue-700/30  text-white"
          >
            Log In
          </button>
        }

      </div>
    </div>
  );
}

export default AdminLoginPage;
