import { Menu } from "lucide-react";
import DrawerMenu from "./drawer";




const Nav = () => {
    return (
        <div className=" w-full  md:px-28 px-8 backdrop-blur bg-white/90  h-[60px] shadow-sm md:h-[80px]  flex items-center justify-center fixed top-0 z-50">
            <div className="mx-auto flex w-full md:max-w-7xl items-center justify-between  py-2  h-12 ">
                <div className="inline-flex items-center ">
                    <span className="font-bold ">Logo</span>

                </div>
                <div className="hidden lg:block">
                    <ul className="ml-12 flex space-x-8">
                        <a href="#"><li className="inline-flex items-center text-sm
                 font-semibold text-gray-800 hover:text-gray-900">Home</li></a>
                        <a href="#insurance"><li className="inline-flex items-center text-sm
                 font-semibold text-gray-800 hover:text-gray-900">Insurance</li></a>
                        <a href="#car_rentals"><li className="inline-flex items-center text-sm 
                font-semibold text-gray-800 hover:text-gray-900">Car Rentals</li></a>
                        <a href="#jobs"> <li className="inline-flex items-center text-sm 
                font-semibold text-gray-800 hover:text-gray-900">Jobs</li></a>

                        <a href="#blood_donation"><li className="inline-flex items-center text-sm              
                font-semibold text-gray-800 hover:text-gray-900">Blood Donation</li>
                        </a>

                    </ul>
                </div>

                <div className="flex grow justify-end">
                    <span className="md:hidden flex"> <DrawerMenu /> </span>
                    {/* <button className="rounded-full bg-gradient-to-b from-blue-600
                             to-blue-900 shadow-lg w-40 h-8 flex-shrink-0 text-white ">Get in Touch</button> */}
                </div>


            </div>
        </div>

    )
}
export default Nav;