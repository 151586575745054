import React, { useEffect, useState } from 'react'
import { Common } from '../forms/common'
import { Insurence } from '../../services/api'
import { Succes } from '../succes'
import FormHeading from '../utils/FormHeading'
import Tab from '../utils/Tab'
import { ForCarIns, ForGeneralIns } from '../insfields'
import { sendEmail } from '../../services/functions'
import { toast, Toaster } from "react-hot-toast";
import { Loader2 } from "lucide-react";

export const InsPage = (props) => {


  const [succes, setsucces] = useState(false)
  const [selected, setSelected] = useState("Life insurence");

  const [ErMsg, setermsg] = useState(false)
  const [loader, setloader] = useState(false);


  const [data, setdata] = useState({ Type: "Insurence", Formtype: selected });
  useEffect(() => { }, [selected, data]);

  const handleonchange = (e) => {
    setdata({ ...data, [e.target.name]: e.target.value, Formtype: selected });
  }
  // api call function 

  const submit = async () => {
    setloader(true)
    // sending mail
    sendEmail(data.Type, data.firstName + " " + data.lastName)
    // console.log(data.Type, data.firstName + " " + data.lastName);
    const res = await Insurence(data)

    if (res == undefined) {
      setermsg(true);
      setloader(false);
    }
    if (res?.status === 200) {
      setsucces(true);
      setloader(false);
    } else if (res?.response?.status === 400) {
      toast.error(res?.response?.data);
      setloader(false);
    }
  }

  return (
    <>
      {succes ? (
        <>
          <Succes />
        </>
      ) : (
        <>
          {" "}
          <div className="h-full w-full flex justify-center items-center bg-gray-100 md:p-8 p-4 flex-col">
            <div className="border-4 border-blue-700 rounded-3xl h-full md:w-[800px] w-full md:p-5 flex items-center justify-center flex-col bg-[#FCFCFE]">
              <div className="flex self-center">
                <FormHeading heading={selected} />
              </div>

              <div className="grid grid-cols-2 md:grid-cols-4  w-full  px-6 gap-4 md:gap-2 mt-10">
                <Tab
                  setSelected={setSelected}
                  selected={selected}
                  name={"Life insurence"}
                  subtittle={"Secure your life"}
                />
                <Tab
                  setSelected={setSelected}
                  selected={selected}
                  name={"Health insurence"}
                  subtittle={"Get Health insurence"}
                />
                <Tab
                  setSelected={setSelected}
                  selected={selected}
                  name={"Car insurence"}
                  subtittle={"Travel with insurence"}
                />
                <Tab
                  setSelected={setSelected}
                  selected={selected}
                  name={"General insurence"}
                  subtittle={"General Insurence"}
                />
              </div>

              <Common handleonchange={handleonchange} />

              {selected == "Car insurence" && (
                <ForCarIns handleonchange={handleonchange} />
              )}
              {selected == "General insurence" && (
                <ForGeneralIns handleonchange={handleonchange} />
              )}
              <p className="text-xs text-gray-600 my-2 text-center">
                Tip: Make sure you have filled in all necessary information
                before submitting the form.
              </p>
              {loader && <h1>Please wait......</h1>}
              {ErMsg && (
                <p className="text-red-500 font-semibold">
                  Please fill all mandatory fields
                </p>
              )}
              {loader ? (
                <button className="rounded-lg  bg-blue-700 p-2 px-10 shadow-lg shadow-blue-700/30 my-4 text-white cursor-not-allowed">
                  <div className="animate-spin">
                    <Loader2 />
                  </div>
                </button>
              ) : (
                <button
                  onClick={submit}
                  className="rounded-lg  bg-blue-700 p-2 px-10 shadow-lg shadow-blue-700/30 my-4 text-white"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
