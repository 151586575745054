
import { useEffect } from 'react';
import './App.css';
import { About } from './components/about';
import ServicesCard from './components/ServicesCard';
import ServicesSection from './components/ServicesSection';
import Home from './components/home';
import Nav from './components/nav';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Adminpage } from './components/adminpage';
import { PageNotFound } from './components/404';

import { JobPage } from './components/formspages/jobpage';
import { BloodPage } from './components/formspages/BloodPage';
import { CarPage } from './components/formspages/CarPage';
import { InsPage } from './components/formspages/inspage';
import { Footer } from './components/footer';
import { Superadmin } from './components/formspages/superadminpage';
import Aos from 'aos';
import 'aos/dist/aos.css'


function App() {

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Nav />
              <Home />
              <ServicesCard />
              <ServicesSection />
              <About />
              <Footer />
            </>
          }
        />

        <Route path="/insurencepage" element={<InsPage />} />
        <Route path="/jobpage" element={<JobPage />} />
        <Route path="/bloodpage" element={<BloodPage />} />
        <Route path="/carpage" element={<CarPage />} />
        <Route path="/adminpage" element={<Adminpage />} />
        <Route path="/superadminpage" element={<Superadmin />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
