



import React, { useEffect, useState } from 'react'
import AdminLoginPage from './AdminLoginPage';
import Apannel from './apannel';



export const Adminpage = (props) => {
  let user = sessionStorage.getItem("user");

  return (
    <>
      {/* <h1>hello</h1> */}
      {
        user ? <Apannel /> : <AdminLoginPage />
      }
    </>
  )
}
