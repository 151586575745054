import React from 'react'

export default function Tab({ setSelected, selected, name ,subtittle}) {

    return (
        <div onClick={() => setSelected(name)} className={`flex justify-around items-center border-2  bg-[#FCFBFB] ${selected === name ? 'border-2 border-[#2173D0] bg-gradient-to-b from-[#ECF2F8] to-[#F6F8FA] shadow-lg shadow-[#2173d015]' : ''}  p-2 rounded-lg cursor-pointer `}>
            <div className="">
                <p className='font-semibold text-[#222222]'>{name}</p>
                <p className='text-xs font-light text-slate-400'>{subtittle}</p>
            </div>
            <div className="">
                <div className={`h-4 w-4 border bg-white rounded-full p-[3px] border-[#ABABAB] ${selected === name ? 'border-[#2173D0]' : ''} flex justify-center items-center`}
                >
                    <div className={`h-full w-full ${selected === name ? 'bg-[#2173D0]' : ''} rounded-full`}></div>
                </div>
            </div>
        </div>
    )
}
