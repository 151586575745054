import { Camera, CarFront, CarTaxiFront, HeartIcon, Flower, HeartPulse, Home, LifeBuoy } from 'lucide-react';

import insurance from './images/insurance.svg'
import car from './images/car.svg'
import job from './images/job.svg'
import blood from './images/blood.svg'

export const services_card_data = [
  {
    id: 0,
    img: insurance,
    head: "Secure your future",
    dis: "Your Safety Net: Dive into Insurance Services",
    btntext: "Get Insurance",
    formaddress: "/insurencepage"
  },
  {
    id: 1,
    img: car,
    head: "Explore the World",
    dis: "Join the Journey: Apply or Drive with ease",
    btntext: "Rent & Drive car",
    formaddress: "/carpage"
  },
  {
    id: 2,
    img: job,
    head: "Find Perfect Job",
    dis: "Apply in Seconds: Way to Your Dream Job",
    btntext: "Find Job",
    formaddress: "/jobpage"
  },
  {
    id: 3,
    img: blood,
    head: "Be a Hero, Donate Blood",
    dis: "Be the Reason for someone heartbeat",
    btntext: "Donate Blood",
    formaddress: "/bloodpage",

  }
]

export const section_data = [
  {
    "id": "insurance",
    "head1": "Secure Your Future",
    "head2": "Smart Insurance for a Happier Life",
    "head3": "We work with several leading insurance companies to secure insurance coverages tailored to meet your needs.",
    "image": "images/ins2.png",
    "image2": "images/ins3.png",
    "btntext": "Apply for Insurance",
    "formaddress": "/insurencepage",
    "class": "flex  items-center w-full md:flex-row flex-col flex-wrap  bg-blue-50 md:pl-10 "
  },
  {
    "id": "car_rentals",
    "head1": "Rent a Car Now or Be a Driver",
    "head2": "Entry 2 Head 2",
    "head3": "Entry 2 Head 3",
    "image": "images/car2.png",
    "image2": "images/car3.png",
    "btntext": "Apply Now"
    ,
    "formaddress": "/carpage",
    "class": "flex justify-center items-center w-full md:flex-row-reverse flex-col-reverse bg-orange-50 bg-opacity-9 md:pr-10"
  },
  {
    "id": "jobs",
    "head1": "Find Your Dream Job",
    "head2": "Active Opportunities For Your Next Career Step",
    "head3": "We're here to help you unlock your potential and find a fulfilling path that aligns with your aspirations. Your future begins here",
    "image": "images/job2.png",
    "image2": "images/job3.png",
    "btntext": "More Opportunities"
    ,
    "formaddress": "/jobpage",
    "class": "flex justify-between items-center w-full md:flex-row flex-col-reverse bg-blue-50 md:pl-10"
  },
  {
    "id": "blood_donation",
    "head1": "Donate Blood, Save Life",
    "head2": "Join Our Blood Donation Mission",
    "head3": "Every drop counts, and your contribution can make a life-changing difference. Hear heartwarming stories from those whose lives you've touched",
    "image": "images/blood2.png",
    "image2": "images/blood3.png",
    "btntext": "Donate Blood"
    ,
    "formaddress": "/bloodpage",
    "class": "flex justify-center items-center w-full md:flex-row-reverse flex-col-reverse  bg-red-50 md:pr-10 "
  }
]

export const insurance_data = [
  {
    "id": 0,
    "icon": < Flower color="#3F9830" size={48} strokeWidth={2} />,
    "title1": "Life Insurance",
    "title2": "Life protection for peace of mind and financial security for your family.",
    "background_color": "#BFF9B5"
  },
  {
    "id": 1,
    "icon": < HeartPulse color="#DC2626" size={48} strokeWidth={2} />,
    "title1": "Health Insurance",
    "title2": "Health coverage for peace of mind and financial security of your loved",
    "background_color": "#FEE2E2"
  },
  {
    "id": 2,
    "icon": <CarFront color="#2563EB" size={48} strokeWidth={2} />,
    "title1": "Car Insurance",
    "title2": "Insurance provide constant protection, ensuring you're covered in any situations",
    "background_color": "#DBEAFE"
  },
  {
    "id": 3,
    "icon": <Home color="#EA580C" size={48} strokeWidth={2} />,
    "title1": "General Insurance",
    "title2": "Our general insurance offers versatile protection, safeguarding to you",
    "background_color": "#FFEDD5"
  },

]

export const dummydata = [

  {
    "serialNumber": 1,
    "fullName": "John Doe",
    "contact": "123-456-7890",
    "email": "john@example.com",
    "address": "123 Main St",
    "type": "Customer"
  },
  {
    "serialNumber": 2,
    "fullName": "Jane Smith",
    "contact": "555-555-5555",
    "email": "jane@example.com",
    "address": "456 Elm St",
    "type": "Customer"
  },
  {
    "serialNumber": 3,
    "fullName": "Bob Johnson",
    "contact": "999-888-7777",
    "email": "bob@example.com",
    "address": "789 Oak St",
    "type": "Vendor"
  },
  {
    "serialNumber": 4,
    "fullName": "Alice Brown",
    "contact": "777-123-4567",
    "email": "alice@example.com",
    "address": "101 Pine St",
    "type": "Customer"
  },
  {
    "serialNumber": 5,
    "fullName": "Eve White",
    "contact": "555-123-4567",
    "email": "eve@example.com",
    "address": "202 Maple St",
    "type": "Vendor"
  },
  {
    "serialNumber": 6,
    "fullName": "Michael Johnson",
    "contact": "555-987-6543",
    "email": "michael@example.com",
    "address": "789 Oak St",
    "type": "Customer"
  },
  {
    "serialNumber": 7,
    "fullName": "Sarah Smith",
    "contact": "777-888-9999",
    "email": "sarah@example.com",
    "address": "303 Elm St",
    "type": "Vendor"
  },
  {
    "serialNumber": 8,
    "fullName": "David Lee",
    "contact": "111-222-3333",
    "email": "david@example.com",
    "address": "555 Birch St",
    "type": "Customer"
  },
  {
    "serialNumber": 9,
    "fullName": "Emily Davis",
    "contact": "444-555-6666",
    "email": "emily@example.com",
    "address": "777 Cedar St",
    "type": "Vendor"
  },
  {
    "serialNumber": 10,
    "fullName": "Daniel Wilson",
    "contact": "222-333-4444",
    "email": "daniel@example.com",
    "address": "888 Oak St",
    "type": "Customer"
  },
  {
    "serialNumber": 11,
    "fullName": "Olivia Thomas",
    "contact": "666-777-8888",
    "email": "olivia@example.com",
    "address": "999 Pine St",
    "type": "Vendor"
  },
  {
    "serialNumber": 12,
    "fullName": "James Brown",
    "contact": "444-333-2222",
    "email": "james@example.com",
    "address": "444 Maple St",
    "type": "Customer"
  },
  {
    "serialNumber": 13,
    "fullName": "Ava Miller",
    "contact": "333-666-9999",
    "email": "ava@example.com",
    "address": "222 Elm St",
    "type": "Vendor"
  },
  {
    "serialNumber": 14,
    "fullName": "William Moore",
    "contact": "777-888-5555",
    "email": "william@example.com",
    "address": "101 Birch St",
    "type": "Customer"
  },
  {
    "serialNumber": 15,
    "fullName": "Sophia Johnson",
    "contact": "555-111-9999",
    "email": "sophia@example.com",
    "address": "303 Oak St",
    "type": "Vendor"
  },
  {
    "serialNumber": 16,
    "fullName": "Benjamin Davis",
    "contact": "999-333-6666",
    "email": "benjamin@example.com",
    "address": "777 Main St",
    "type": "Customer"
  },
  {
    "serialNumber": 17,
    "fullName": "Charlotte Wilson",
    "contact": "444-222-7777",
    "email": "charlotte@example.com",
    "address": "555 Cedar St",
    "type": "Vendor"
  },
  {
    "serialNumber": 18,
    "fullName": "Mason Martinez",
    "contact": "222-555-4444",
    "email": "mason@example.com",
    "address": "888 Elm St",
    "type": "Customer"
  },
  {
    "serialNumber": 19,
    "fullName": "Amelia Taylor",
    "contact": "666-888-7777",
    "email": "amelia@example.com",
    "address": "999 Birch St",
    "type": "Vendor"
  },
  {
    "serialNumber": 20,
    "fullName": "Liam Anderson",
    "contact": "333-444-5555",
    "email": "liam@example.com",
    "address": "444 Oak St",
    "type": "Customer"
  },
  {
    "serialNumber": 21,
    "fullName": "Evelyn White",
    "contact": "777-111-6666",
    "email": "evelyn@example.com",
    "address": "222 Main St",
    "type": "Vendor"
  },
  {
    "serialNumber": 22,
    "fullName": "Harper Lee",
    "contact": "111-555-4444",
    "email": "harper@example.com",
    "address": "303 Maple St",
    "type": "Customer"
  },
  {
    "serialNumber": 23,
    "fullName": "Sebastian Adams",
    "contact": "555-444-3333",
    "email": "sebastian@example.com",
    "address": "666 Pine St",
    "type": "Vendor"
  },
  {
    "serialNumber": 24,
    "fullName": "Aria Turner",
    "contact": "222-777-1111",
    "email": "aria@example.com",
    "address": "777 Elm St",
    "type": "Customer"
  },
  {
    "serialNumber": 25,
    "fullName": "Jackson Wright",
    "contact": "999-333-5555",
    "email": "jackson@example.com",
    "address": "888 Cedar St",
    "type": "Vendor"
  },

  {
    "serialNumber": 1,
    "fullName": "John Doe",
    "contact": "123-456-7890",
    "email": "john@example.com",
    "address": "123 Main St",
    "type": "Customer"
  },
  {
    "serialNumber": 2,
    "fullName": "Jane Smith",
    "contact": "555-555-5555",
    "email": "jane@example.com",
    "address": "456 Elm St",
    "type": "Customer"
  },
  {
    "serialNumber": 3,
    "fullName": "Bob Johnson",
    "contact": "999-888-7777",
    "email": "bob@example.com",
    "address": "789 Oak St",
    "type": "Vendor"
  },
  {
    "serialNumber": 4,
    "fullName": "Alice Brown",
    "contact": "777-123-4567",
    "email": "alice@example.com",
    "address": "101 Pine St",
    "type": "Customer"
  },
  {
    "serialNumber": 5,
    "fullName": "Eve White",
    "contact": "555-123-4567",
    "email": "eve@example.com",
    "address": "202 Maple St",
    "type": "Vendor"
  },
  {
    "serialNumber": 6,
    "fullName": "Michael Johnson",
    "contact": "555-987-6543",
    "email": "michael@example.com",
    "address": "789 Oak St",
    "type": "Customer"
  },
  {
    "serialNumber": 7,
    "fullName": "Sarah Smith",
    "contact": "777-888-9999",
    "email": "sarah@example.com",
    "address": "303 Elm St",
    "type": "Vendor"
  },
  {
    "serialNumber": 8,
    "fullName": "David Lee",
    "contact": "111-222-3333",
    "email": "david@example.com",
    "address": "555 Birch St",
    "type": "Customer"
  },
  {
    "serialNumber": 9,
    "fullName": "Emily Davis",
    "contact": "444-555-6666",
    "email": "emily@example.com",
    "address": "777 Cedar St",
    "type": "Vendor"
  },
  {
    "serialNumber": 10,
    "fullName": "Daniel Wilson",
    "contact": "222-333-4444",
    "email": "daniel@example.com",
    "address": "888 Oak St",
    "type": "Customer"
  },
  {
    "serialNumber": 11,
    "fullName": "Olivia Thomas",
    "contact": "666-777-8888",
    "email": "olivia@example.com",
    "address": "999 Pine St",
    "type": "Vendor"
  },
  {
    "serialNumber": 12,
    "fullName": "James Brown",
    "contact": "444-333-2222",
    "email": "james@example.com",
    "address": "444 Maple St",
    "type": "Holeseller"
  },
  {
    "serialNumber": 13,
    "fullName": "Ava Miller",
    "contact": "333-666-9999",
    "email": "ava@example.com",
    "address": "222 Elm St",
    "type": "Vendor"
  },
  {
    "serialNumber": 14,
    "fullName": "William Moore",
    "contact": "777-888-5555",
    "email": "william@example.com",
    "address": "101 Birch St",
    "type": "Buisness"
  },
  {
    "serialNumber": 15,
    "fullName": "Sophia Johnson",
    "contact": "555-111-9999",
    "email": "sophia@example.com",
    "address": "303 Oak St",
    "type": "Vendor"
  },
  {
    "serialNumber": 16,
    "fullName": "Benjamin Davis",
    "contact": "999-333-6666",
    "email": "benjamin@example.com",
    "address": "777 Main St",
    "type": "Customer"
  },
  {
    "serialNumber": 17,
    "fullName": "Charlotte Wilson",
    "contact": "444-222-7777",
    "email": "charlotte@example.com",
    "address": "555 Cedar St",
    "type": "Vendor"
  },
  {
    "serialNumber": 18,
    "fullName": "Mason Martinez",
    "contact": "222-555-4444",
    "email": "mason@example.com",
    "address": "888 Elm St",
    "type": "Customer"
  },
  {
    "serialNumber": 19,
    "fullName": "Amelia Taylor",
    "contact": "666-888-7777",
    "email": "amelia@example.com",
    "address": "999 Birch St",
    "type": "Vendor"
  },
  {
    "serialNumber": 20,
    "fullName": "Liam Anderson",
    "contact": "333-444-5555",
    "email": "liam@example.com",
    "address": "444 Oak St",
    "type": "Customer"
  },
  {
    "serialNumber": 21,
    "fullName": "Evelyn White",
    "contact": "777-111-6666",
    "email": "evelyn@example.com",
    "address": "222 Main St",
    "type": "Vendor"
  },
  {
    "serialNumber": 22,
    "fullName": "Harper Lee",
    "contact": "111-555-4444",
    "email": "harper@example.com",
    "address": "303 Maple St",
    "type": "Customer"
  },
  {
    "serialNumber": 23,
    "fullName": "Sebastian Adams",
    "contact": "555-444-3333",
    "email": "sebastian@example.com",
    "address": "666 Pine St",
    "type": "Vendor"
  },
  {
    "serialNumber": 24,
    "fullName": "Aria Turner",
    "contact": "222-777-1111",
    "email": "aria@example.com",
    "address": "777 Elm St",
    "type": "Customer"
  },
  {
    "serialNumber": 25,
    "fullName": "Jackson Wright",
    "contact": "999-333-5555",
    "email": "jackson@example.com",
    "address": "888 Cedar St",
    "type": "Vendor"
  },
]


