import React, { useEffect, useState } from 'react';
import MyTable from './table';
import { ToExcel } from './excelmaker';
import { dummydata } from '../const';
import { GetDataApi } from '../services/api';
import AdminCard from './utils/AdminCard';
import { Sheet } from 'lucide-react';



function Apannel({ setauth }) {
  const [data, setdata] = useState([])
  const apicall = async () => {
    let data1 = await GetDataApi();

    setdata(data1.data)
  }
  useEffect(() => {
    apicall();
  }, [])
  const logout = () => {

    sessionStorage.clear();
    window.location.reload(true);

  };
  console.log("i am data==>", data);
  function filterDataByType(targetType) {

    const filteredData = data.filter((item) => item.Type === targetType);

    return filteredData;
  }
  const Blood = filterDataByType("BloodDonation");
  const Car = filterDataByType("CarRental");
  const insurence = filterDataByType("Insurence");
  const Jobs = filterDataByType("Jobs");


  const [selected, setSelected] = useState("Insurance")

  const card_data = [
    {
      id: 1,
      name: 'Insurance',
      number: insurence.length,
      tableData: insurence
    },
    {
      id: 2,
      name: 'Boold Donation',
      number: Blood.length,
      tableData: Blood
    },
    {
      id: 3,
      name: 'Jobs',
      number: Jobs.length,
      tableData: Jobs
    },
    {
      id: 4,
      name: 'Car Rental',
      number: Car.length,
      tableData: Car
    },
  ]


  return (
    <div className="flex p-5  justify-between bg-gray-100 w-screen h-full">

      <div id="data" className="flex flex-col  items-start p-2 w-full h-full ">

        <div id="data_card" className="flex justify-center items-center mb-5">

          {
            card_data.map((data) => (
              <AdminCard key={data.id} selected={selected} setSelected={setSelected} name={data.name} number={data.number} />
            ))
          }
          <div id="card" className="rounded-xl  h-[100px] w-[250px] bg-inherit p-2 flex flex-col justify-center items-start mx-5 ">
            <button className="rounded-full w-40 p-2 m-4 bg-blue-600 text-white " onClick={logout}>
              Logout
            </button>

          </div>
        </div>
        <div id="table" className="w-full">

          {

            card_data.map((card_data) => (

              selected === card_data.name && <>
                {
                  card_data.length !== 0 ?
                    <><div className="flex justify-between my-4">
                      <h1 className='font-bold text-xl mb-4'>{card_data.name}</h1>
                      <div className="flex px-4 py-2 rounded-md items-center justify-center cursor-pointer hover:border hover:border-green-500 hover:text-green-500 hover:bg-white bg-green-500 text-white w-fit shadow-lg shadow-green-100">
                        <Sheet />
                        <p className='ml-2'>  <ToExcel data={card_data.tableData} /></p>
                      </div>
                    </div>

                      <div className="w-[100%] overflow-x-scroll border-r">
                        <MyTable data={card_data.tableData} />

                      </div></> :
                    <><div className='w-full h-[70vh] flex justify-center items-center'>
                      <h1 className='font-extrabold text-6xl'>No Data Found</h1>
                    </div></>
                }

              </>
            ))
          }
          {/* 
          {
            selected === "Insurance" && <><h1 className='font-bold text-xl mb-4'>Insurence</h1>
              <div className="w-[95%] overflow-x-scroll border-r">
                <MyTable data={insurence} />

              </div>
            </>
          }
          {
            selected === "Boold Donation" && <><h1 className='font-bold text-xl mb-4'>{selected}</h1>
              <div className="w-[95%] overflow-x-scroll border-r">
                <MyTable data={Blood} />

              </div>
            </>
          }
          {
            selected === "Jobs" && <><h1 className='font-bold text-xl mb-4'>{selected}</h1>
              <div className="w-[95%] overflow-x-scroll border-r">
                <MyTable data={Jobs} />

              </div>
            </>
          }
          {
            selected === "Car Rental" && <><h1 className='font-bold text-xl mb-4'>{selected}</h1>
              <div className="w-[95%] overflow-x-scroll border-r">
                <MyTable data={Car} />

              </div>
            </>
          } */}

        </div>


      </div>


    </div>
  );
}

export default Apannel;
