import { Menu } from 'lucide-react'
import React from 'react'

import Drawer from 'react-modern-drawer'

import 'react-modern-drawer/dist/index.css'

const DrawerMenu = () => {
    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    return (
        <>

            <button onClick={toggleDrawer}><Menu size={30} strokeWidth={2.2} /></button>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='top'
                style={{
                    height: '200px',
                    backgroundColor: 'transparent'


                }}
            >
                <div className="w-full h-full  flex items-center justify-center flex-col  bg-white">
                    <button onClick={toggleDrawer} className="m-2 active:text-blue-800 font-bold"><a href="#"><li className="inline-flex items-center text-sm
                 font-semibold text-gray-800 hover:text-gray-900">Home</li></a></button>
                    <button onClick={toggleDrawer} className="m-2 active:text-blue-800 font-bold"><a href="#insurance"><li className="inline-flex items-center text-sm
                 font-semibold text-gray-800 hover:text-gray-900">Insurance</li></a></button>
                    <button onClick={toggleDrawer} className="m-2 active:bg-blue-800 font-bold"><a href="#car_rentals"><li className="inline-flex items-center text-sm 
                font-semibold text-gray-800 hover:text-gray-900">Car Rentals</li></a></button>
                    <button onClick={toggleDrawer} className="m-2 active:bg-blue-800 font-bold">  <a href="#jobs"> <li className="inline-flex items-center text-sm 
                font-semibold text-gray-800 hover:text-gray-900">Jobs</li></a></button>
                    <button onClick={toggleDrawer} className="m-2 active:bg-blue-800 font-bold"> <a href="#blood_donation"><li className="inline-flex items-center text-sm              
                font-semibold text-gray-800 hover:text-gray-900">Blood Donation</li></a></button>
                    <i onClick={toggleDrawer} className="fa fa-times absolute top-4 right-4  fa-fw"></i>
                </div>
            </Drawer>
        </>
    )
}

export default DrawerMenu;