



export const ForCarIns = ({ handleonchange }) => {
    return (
      <div className=" flex justify-center items-center flex-wrap ">
        <div className="md:w-[46%] w-[100%] px-4 ">
          <p>Vehicle regestration number</p>
          <input
            onChange={(e) => {
              handleonchange(e);
            }}
            className="border-[1px] border-[#828589] p-2 py-1 my-2 md:m-2  rounded-lg w-full "
            type="text"
            name="Vehicle regestration number"
            placeholder="Enter Vehicle regestration number"
          />
        </div>
        <div className="md:w-[46%] w-[100%] px-4 ">
          <p>Vehicle make and modal</p>
          <input
            onChange={(e) => {
              handleonchange(e);
            }}
            className="border-[1px] border-[#828589] p-2 py-1 my-2 md:m-2  rounded-lg w-full"
            type="text"
            name="Vehicle make and modal"
            placeholder="Enter Vehicle make and modal"
          />
        </div>
        <div className=" px-4 mt-3 md:w-[46%] w-[100%]">
          <p> Vehicle Manufacturing year</p>
          <input
            onChange={(e) => {
              handleonchange(e);
            }}
            className="border-[1px] border-[#828589] p-2 py-1 my-2  md:m-2 rounded-lg w-full"
            type="text"
            name="Vehicle Manufacturing year"
            placeholder="Enter Vehicle Manufacturing year"
          />
        </div>
        <div className="md:w-[46%] w-[100%] px-4 mt-3">
          <p>Vehicle Chassis number</p>
          <input
            onChange={(e) => {
              handleonchange(e);
            }}
            className="border-[1px] border-[#828589] p-2 py-1 my-2 md:m-2  rounded-lg w-full"
            type="text"
            name="Vehicle chassis number"
            placeholder="Enter Vehicle Chassis number"
          />
        </div>
        <div className="md:w-[46%] w-[100%] mt-3 px-4">
          <p>Vehicle Engine number</p>
          <input
            onChange={(e) => {
              handleonchange(e);
            }}
            className="border-[1px] border-[#828589] p-2 py-1 my-2 md:m-2 rounded-lg w-full"
            type="text"
            name="Vehicle engine number"
            placeholder="Enter Vehicle Engine number"
          />
        </div>
        <div className="md:w-[46%] w-[100%] mt-3  px-4">
          <p>Type of vhicle</p>
          <select
            onChange={(e) => {
              handleonchange(e);
            }}
            className="border-[1px] border-[#828589] p-2 py-2 my-2 md:m-2 rounded-lg w-full"
            name="Type of vhicle"
          >
            <option>private car</option>
            <option>two whiller</option>
            <option>four whiller</option>
          </select>
        </div>
      </div>
    );
}



export const ForGeneralIns = ({ handleonchange }) => {
    return (
        <div className="w-full px-4  flex justify-center items-center ">
            <select onChange={(e) => { handleonchange(e) }} className="border-[1px] border-[#828589]border-[1px] border-[#828589] w-full  p-2 py-2 m-2 rounded-lg" name="generalinsurencetypes"
            >
                <option>Health Insurence</option>
                <option>Homeowners Insurence</option>
                <option>Personal accident Insurence</option>
                <option>Mobile phone Insurence</option>
                <option>Crop Insurence</option>
                <option>Fire Insurence</option>
                <option>jwlery Insurence</option>
            </select>
        </div>
    )
}
export const ForlifeIns = () => {
    return (
        <div>

        </div>
    )
}

export const Forbloodpage = () => {
    return (
        <div className=" flex flex-col justify-start w-full px-8">
            <div>
                <p className="py-2">Do you have an infectious disease ?</p>
                <div className="flex">
                    <span className="flex"> <input type="radio" name="hasInfection" value="yes" /><h3 className="mx-3">Yes</h3></span>
                    <span className="flex">  <input type="radio" name="hasInfection" value="No" /><h3 className="mx-3">No</h3></span>
                </div>
            </div>
            <div>
                <p className="py-2">Is there a genetic disease in your medical history ?</p>
                <div className="flex">
                    <span className="flex"> <input type="radio" name="hasgeneticdisease" value="yes" /><h3 className="mx-3">Yes</h3></span>
                    <span className="flex">  <input type="radio" name="hasgeneticdisease" value="No" /><h3 className="mx-3">No</h3></span>
                </div>
            </div>
            <div>
                <p className="py-2">Is there an ongoing infectious disease ?</p>
                <div className="flex">
                    <span className="flex"> <input type="radio" name="hasongoingInfection" value="yes" /><h3 className="mx-3">Yes</h3></span>
                    <span className="flex">  <input type="radio" name="hasongoingInfection" value="No" /><h3 className="mx-3">No</h3></span>
                </div>
            </div>
            <div>
                <p className="py-2">Are you currently undergoing any treatment ?</p>
                <div className="flex">
                    <span className="flex"> <input type="radio" name="hastreatment" value="yes" /><h3 className="mx-3">Yes</h3></span>
                    <span className="flex">  <input type="radio" name="hastreatment" value="No" /><h3 className="mx-3">No</h3></span>
                </div>
            </div>
            <div>
                <p className="py-2">Have you ever had a tattoo done ?</p>
                <div className="flex">
                    <span className="flex"> <input type="radio" name="hastattoo" value="yes" /><h3 className="mx-3">Yes</h3></span>
                    <span className="flex">  <input type="radio" name="hastattoo" value="No" /><h3 className="mx-3">No</h3></span>
                </div>
            </div>
            <div>
                <p className="py-2">Do you have any allergies ?</p>
                <div className="flex">
                    <span className="flex"> <input type="radio" name="hasallergies" value="yes" /><h3 className="mx-3">Yes</h3></span>
                    <span className="flex">  <input type="radio" name="hasallergies" value="No" /><h3 className="mx-3">No</h3></span>
                </div>
            </div>



        </div>
    )
}