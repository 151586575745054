import React from 'react'


export const PageNotFound = (props) => {
  return (
    <div className="flex justify-center items-center h-[500px]">
      <span className="text-3xl font-extrabold ">404 Page not found</span>
    </div>
  )
}
